<template>
  <div class="container">
    <poster :poster-list="posterList" :page-path="pagePath" :module-key="moduleKey" :token="token" :time-format="4" />
  </div>
</template>

<script>
import Poster from '../../../components/Poster/Index'

export default {
  name: "Double11MWPoster",
  components: {
    Poster
  },
  data() {
    return {
      token: this.$route.query.token,
      posterList: [
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221031143135985.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221031143159887.png',
          textColor: '#ffffff',
          textFontSize:26,
          tempPoster: '',
          textPoint: [430, 1203],
          wxCode: { width: 188, point: [58, 1131] }
        },
        {
          backgroundImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221031143425136.png',
          tempBgImage: null,
          miniImage: 'http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20221031143211208.png',
          textColor: '#ffffff',
          textFontSize:26,
          tempPoster: '',
          textPoint: [430, 1203],
          wxCode: { width: 188, point: [58, 1131] }
        }
      ],
      pagePath: this.baseHost + '/',
      moduleKey: ''
    }
  },
  created() {
    this.moduleKey = this.token ? '40e3c808-2243-4e9d-95a5-70ed5adfc34a' : 'gzhXMssy20221111'
  }
}
</script>

<style scoped>
</style>